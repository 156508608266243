@import "../assets/styles/variables.scss";

.recipe-list {
  display: flex;
  justify-content: center;
  flex-flow: wrap;
  flex-direction: row;
  gap: 10px;
  height: 100%;
  width: 100%;
}
.recipe-img {
  object-fit: cover;
  width: 100%;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 10px;
  float: left;
}

.recipe-post-container {
  background-color: #fff;
  height: 100%;
  padding-bottom: 50px;
}
.recipe-header-container {
  width: 100%;
  height: 45px;
  box-shadow: 0px 5px 6px #0000006b;
  display: flex;
  align-items: center;
  color: #333333;
  font-weight: bold;
}
.recipe-overview-container {
  height: 80px;
  border-bottom: 1px solid #000;
  border-top: 1px solid #000;
  background: #ffffff 0% 0% no-repeat padding-box;
  display: flex;
  align-items: center;
  padding-left: 18px;
  padding-right: 18px;
  font-size: 12px;
}

.recipe-title {
  font-size: 20px;
  font-weight: bold;
  margin-top: 0px;
}

.recipe-header {
  font-weight: bold;
  letter-spacing: 0px;
  color: #000000;
  margin-bottom: 5px;
  margin-top: 5px;
}
.recipe-ingredients-container {
  margin: 5px;
  padding-left: 13px;
  padding-right: 13px;
  padding-top: 10px;
  padding-bottom: 10px;
  box-shadow: 0px 3px 6px #00000029;
}
.recipe-text-container {
  font-size: 16px;
  letter-spacing: 0px;
  color: #000000;
  display: flex;
  flex-direction:column;
}

.recipe-title-startpage {
  text-align: center;
  /*background: linear-gradient(to left top, #FFB2A6, #80BFC4); */
  padding: 10px 30px 20px 30px;
  /*background-color: #e5e5e5;*/
  background-color: #fff;
  margin-top: 10px;
  //border-radius: 10px;
}

@media #{$media-small} {
  .recipe-title {
    font-size: 11px;
  }
}
