@import "../assets/styles/variables.scss";
.startPageContainer {
  display: flex;
  justify-content: space-between;
  margin-top: 15px;
  flex-direction: column;
}

.startPageContainerChild {
  width: 100%;
}

.startPageHeaderContainer {
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  background-color: #0C7D89;
  height: 65px;
  display: flex;
  font-size: 16px;
  font-weight: bold;
  justify-content: space-between;
  align-items: center;
  padding-left: 15px;
  padding-right: 15px;
  cursor: pointer;
  margin-bottom: 5px;
  color: #fff;
  //border-radius: 10px;
}

.startPageContainer .listContainer {
  height: 200px;
}

.startPageContainer .first-comment-text {
  height: 65px;
}

.right-arrow {
  height: 25px;
}

.trophy-section {
  display: block;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  background-color: #0C7D89;
  height: 65px;
  //border-radius: 10px;
  font-size: 16px;
  margin-bottom: 5px;
}

.trophy-icon {
  float: left;
  width: 25px;
  padding-top: 20px;
  margin-left: 10px;
}

.txt-top-posts {
  padding-top: 20px;
  margin-left: 40px;
  color: #fff;
  font-weight: bold;
}

@media #{$media-big} {
  .startPageContainer {
    flex-direction: row;
  }
  .startPageContainerChild {
    width: 49%;
  }
}

@media #{$media-small} {
  .startPageHeaderContainer {
    font-size: 15px;
    height: 45px;
  }
  .trophy-section {
    height: 45px;
    font-size: 15px;
  }
  .trophy-icon {
    padding-top: 10px;
    margin-left: 10px;
    width: 20px;
  }

  .txt-top-posts {
    padding-top: 10px;
    margin-left: 35px;
  }
  .startPageContainer .listContainer {
    height: auto;
  }
  .startPageContainer .first-comment-text {
    height: auto;
  }
}
