@import "../assets/styles/variables.scss";

.ads-container {
  content: "";
  //background-image: url("../assets/images/Form-baby.svg");
  background-color:#d5dbdc;
  color:#000000;
  background-size: cover;
  max-width: 100%;
  background-repeat: no-repeat;
  padding: 20px 2px 20px 2px;
  // background: url(background-photo.jpg) center center cover no-repeat fixed;
}

.ads-imageBox {
  display: flex;
  padding: 5px;
}

.ads-image-container {
  width: 286.1px;
  height: 100%;
  opacity: 0.92;
  border-radius: 15px;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  background-color: #ffffff;
  flex: auto;
  margin-right: 20px;
}

.ads-image-container h2 {
  color: #000;
  text-align: center;
  font-size: 20px;
}

.ads-image-container Button {
  width: 258px;
  height: 58px;
  border-radius: 30px;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  background-color: #0c7d89;
  color:#ffffff;
  border: none;
  text-align: center;
  display: block;
  margin-left: auto;
  margin-right: auto;
  margin-top: 20px;
  margin-bottom: 20px;
  cursor: pointer;
  font-size: 14px !important;
  text-transform: uppercase;
}

.ads-image {
  opacity: 0.92;
  width: 257.6px;
  height: 182.8px;
  object-fit: cover;
  opacity: 0.92;
  border-top: 15px;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  background-repeat: no-repeat;
  margin-left: auto;
  margin-right: auto;
  display: block;
  position: relative;
}

.more-ads h2 {
  text-decoration: underline;
  text-align: right;
  cursor: pointer;
}

.more-ads {
  margin-top: 30px;
}

.heart-icon {
  margin-right: 4px;
  float: right;
}

.ads-text {
  margin-left: 15px;
  margin-right: 15px;
}
.ads-text-contact {
  padding-left: 15px;
  padding-right: 15px;
}

@media #{$media-small} {
  .ads-image-container {
    height: 100%;
    width: 100%;
    margin-right: 4px;
    padding: 4px;
    z-index: 0;
    margin-top: 10px;
  }
  .ads-image {
    height: 200px;
    width: 100%;
  }
  .ads-image-container Button {
    height: 100%;
    width: 100%;
    font-size: 11px !important;
    margin-top: 5px;
    margin-bottom: 5px;
  }
  .ads-image-container h2 {
    font-size: 16px;
  }
  .ads-text {
    font-size: 16px;
  }
  .ads-text-contact {
    font-size: 11px;
    font-weight: normal;
  }
  .more-ads h2 {
    font-size: 16px;
  }
  .ads-imageBox {
    flex-wrap: wrap-reverse;
  }
}

@media #{$media-medium} {
  .ads-image-container h2 {
    font-size: 14px;
  }
  .ads-text {
    font-size: 20px;
  }
  .ads-text-contact {
    font-size: 15px;
    font-weight: normal;
  }
  .more-ads h2 {
    font-size: 20px;
  }
  .ads-image {
    height: 220px;
    width: 100%;
  }
  .ads-image-container {
    margin: 10px;
  }

  .ads-imageBox {
    flex-wrap: wrap;
    margin-right: 4px;
  }
}
